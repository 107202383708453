import BannerCarousel from "../../components/home/bannerCarousel";
import ResortCarousel from "../../components/home/resortCarousel";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { config } from "../../constants";
import axios from "axios";


const Home = () => {
    const Navigate = useNavigate();
    const resortList = useSelector((state) => state.resort.resortList);
    const staticContentList = useSelector((state) => state.staticContent.staticContent);
    let user_details = JSON.parse(localStorage.getItem('user_details'));

    console.log('resort list dropdown home ', resortList);
    useEffect(() => {
        actions.getResortListAction();

        let static_content_data = { slug: "covid-19-checkup" };
        actions.getStaticContentAction(static_content_data);
    }, []);

    const today = new Date();
    const currentDate = moment(today).format('YYYY-MM-DD');
    const nextDate = moment(currentDate, "YYYY-MM-DD").add(2, 'days').format('YYYY-MM-DD');
    // console.log('today date ',nextDate);

    const [formData, setFormData] = useState({
        holidayType: '',
        checkInDate: currentDate,
        checkOutDate: nextDate,
        rooms: 1,
        adults: 2,
        childrens: 0,
        infant: 0
    });

    // const handleChange = (e) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    console.log({ user_details });
    const handleIncrement = () => {
        setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms + 1 }));
    };
    const handleDecrement = () => {
        if (formData?.rooms > 0) {
            setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms - 1 }));
        }
    };

    const handleAdultIncrement = () => {
        setFormData((prevState) => ({ ...prevState, adults: prevState?.adults + 1 }));
    }
    const handleAdultDecrement = () => {
        if (formData?.adults > 0) {
            setFormData((prevState) => ({ ...prevState, adults: prevState?.adults - 1 }));
        }
    }

    const handleChildrenIncrement = () => {
        setFormData((prevState) => ({ ...prevState, childrens: prevState?.childrens + 1 }));
    }
    const handleChildrenDecrement = () => {
        if (formData?.childrens > 0) {
            setFormData((prevState) => ({ ...prevState, childrens: prevState?.childrens - 1 }));
        }
    }

    const handleinfantIncrement = () => {
        setFormData((prevState) => ({ ...prevState, infant: prevState?.infant + 1 }));
    }
    const handleInfantDecrement = () => {
        if (formData?.infant > 0) {
            setFormData((prevState) => ({ ...prevState, infant: prevState?.infant - 1 }));
        }
    }

    const handleSearch = () => {
        Navigate('/resort-list', { state: { searchData: formData } })
    }


    //calander on hover
    const [showCalendar, setShowCalendar] = useState(false);
    const [showCheckOutCalander, setShowCheckOutCalander] = useState(false);

    const handleMouseEnter = () => {
        setShowCalendar(true);
        setShowCheckOutCalander(false);
    };
    const handleMouseLeave = () => {
        setShowCalendar(false);
    };

    const handleMouseEnterCheckOut = () => {
        setShowCalendar(false);
        setShowCheckOutCalander(true);
    }
    const handleMouseLeaveCheckOut = () => {
        setShowCheckOutCalander(false);
    }

    //Holiday type list for dropdown list
    const holidayTypeList = useSelector((state) => state?.resort?.resortHolidayList?.data);

    const getTransactionList = async () => {
        if (window?.location?.href?.includes('transactionId')) {
            let s1 = window?.location?.href.split('?');
            let s2 = s1?.at(1)?.split("&");
            let s3 = s2?.at(0)?.split("=")?.at(1)
            console.log(s3)
            if (s2?.at(1) == "state=CONFIRMED") {
                const result = await axios.get(`${config.API_BASE_URL}/api/get-status/${s3}`);
                console.log({ result })
                if (result?.status === 200) {
                    toast.success("Payment Successfully", {
                        position: 'top-right',
                        autoClose: 3000
                    })
                    const data = {
                        user_id: user_details?.id,


                    }
                    const result = await axios.post(`${config.API_BASE_URL}/api/get-status/${s3}`);
                } else {
                    console.error('Payment failed');
                }

            }
        }
    }
    useEffect(() => {
        actions.getResortHolidayAction();
        getTransactionList();
    }, []);

    console.log("Main Page", window.location)




    return (
        <FooterHeaderWrapper>
            <section>
                <div className="hero-section" id="hero-section">
                    <span className="BestStays">#1 Best Stays</span>
                    <h2>Discover the beauty of <br /> the <span>Maldives</span> with us</h2>
                    <p>Trust your vacation to our travel experts and experience the unforgettable sensation of <br /> exploring the
                        world.</p>
                    <div className="HeroProcess">
                        <div className="HeroSmallBox Custom HolidayTypesHover">
                            {/* <div className="form-group"> */}
                            <div className="form-group">
                                <h6>Holiday Type</h6>
                                <p>{formData?.holidayType ? formData?.holidayType : 'Select Category'}</p>
                            </div>
                            <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                            <div className="HolidayTypesList">
                                <ul>
                                    {
                                        holidayTypeList && holidayTypeList?.map((item, index) => (
                                            <li key={index} onClick={() => setFormData(prevState => ({ ...prevState, "holidayType": item?.holiday_type }))}><Link to="#">{item?.holiday_type}</Link></li>
                                        ))
                                    }

                                </ul>
                            </div>

                            <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                        </div>
                        <div className="HeroSmallBox HeroSmallBoxDate">
                            <div className="hover-datepicker"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <img src={require("../../assets/images/icon-1.png")} alt="img" />
                                <h6>Check in</h6>
                                <p>Add Date</p>
                                <DatePicker
                                    width='100%'
                                    // minDate={today}
                                    selected={formData?.checkInDate}
                                    onChange={(date) => setFormData((prevState) => ({ ...prevState, checkInDate: date }))}
                                    open={showCalendar}
                                    onFocus={() => setShowCalendar(true)}
                                    onBlur={() => setShowCalendar(false)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Add date"
                                    name="checkInDate"
                                    monthsShown={1}
                                    selectsStart
                                    // selectsEnd
                                    // selectsRange={true}
                                    // onChange={(update) => {
                                    //     setFormData((prevState) => ({ ...prevState, checkInDate: update }))
                                    //   }}
                                    startDate={formData?.checkInDate}
                                    endDate={formData?.checkOutDate}
                                />
                            </div>
                        </div>

                        <div className="HeroSmallBox HeroSmallBoxDate ">
                            <div className="hover-datepicker"
                                onMouseEnter={handleMouseEnterCheckOut}
                                onMouseLeave={handleMouseLeaveCheckOut}
                            >
                                <img src={require("../../assets/images/icon-1.png")} alt="img" />
                                <h6>Check Out</h6>
                                <p>Add Date</p>
                                <DatePicker
                                    width='100%'
                                    open={showCheckOutCalander}
                                    onFocus={() => setShowCheckOutCalander(true)}
                                    onBlur={() => setShowCheckOutCalander(false)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Add date"
                                    className="form-control"
                                    name="checkOutDate"
                                    selected={formData?.checkOutDate}
                                    onChange={(date) => setFormData((prevState) => ({ ...prevState, checkOutDate: date }))}
                                    minDate={formData?.checkInDate}
                                    selectsEnd
                                    startDate={formData?.checkInDate}
                                    endDate={formData?.checkOutDate}
                                    monthsShown={1}
                                />
                            </div>
                        </div>

                        <div className="HeroSmallBox Custom RoomsTypeHover">
                            <div className="form-group">
                                <h6>Rooms</h6>
                                <p>Number of Rooms ({formData?.rooms})</p>
                            </div>
                            <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                            <div className="RoomsTypesOuter First">
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Rooms</h6>
                                        <p>Number of Rooms</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span onClick={handleDecrement}><i className="fa-solid fa-minus"></i></span>
                                        <h6>{formData?.rooms}</h6>
                                        <input type="hidden" name="rooms" value={formData?.rooms} />
                                        <span onClick={handleIncrement}><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="HeroSmallBox Custom TravelersHover">
                            <div className="form-group">
                                <h6>Travelers</h6>
                                <p>Add Guests ({formData?.adults} + {formData?.childrens} + {formData?.infant})</p>
                            </div>
                            <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                            <div className="RoomsTypesOuter Second">
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Adults</h6>
                                        <p>Ages 13 or above</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span onClick={handleAdultDecrement}><i className="fa-solid fa-minus"></i></span>
                                        <h6>{formData?.adults} <input type="hidden" name="adults" value={formData?.adults} /></h6>
                                        <span onClick={handleAdultIncrement}><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Children</h6>
                                        <p>Ages 2 - 12</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span onClick={handleChildrenDecrement}><i className="fa-solid fa-minus"></i></span>
                                        <h6>{formData?.childrens} <input type="hidden" name="childrens" value={formData?.childrens} /></h6>
                                        <span onClick={handleChildrenIncrement}><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Infant</h6>
                                        <p>Ages 0 - 2</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span onClick={handleInfantDecrement}><i className="fa-solid fa-minus"></i></span>
                                        <h6>{formData?.infant} <input type="hidden" name="infant" value={formData?.infant} /></h6>
                                        <span onClick={handleinfantIncrement}><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="form-group mb-0">
                            <span className="SearchBtn" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i></span>
                        </div>
                    </div>
                    {/* <div className="SocialIcons">
                        <ul>
                            <li><Link to="#"><i className="fa-brands fa-instagram"></i></Link></li>
                            <li><Link to="#"><i className="fa-brands fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fa-brands fa-youtube"></i></Link></li>
                        </ul>
                    </div> */}
                </div>
            </section>

            <div id="Main">
                <div className="LearnMoreArea">
                    {/* <p><img src={require("../../assets/images/danger.png")} alt="img" />
                        {
                            staticContentList?.data ? staticContentList?.data?.title : "Check the latest COVID-19 restrictions before you travel."
                        }
                        <Link to="/covid-guidelines"><span>Learn more</span></Link></p> */}
                </div>
                <BannerCarousel />
                <ResortCarousel />
            </div>
        </FooterHeaderWrapper>
    )
}

export default Home;